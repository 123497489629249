<script>
  const currentYear = new Date().getFullYear();
</script>

<footer>
  <div class="footer-content">
    <img
      src="/img/beeldmerk-noahkappers.svg"
      alt="Beeldmerk van Noah Kappers"
      class="logo"
    />
    <p>Noah Kapper | {currentYear}</p>
    <span class="version">
      <a
        href="https://faktor22.nl"
        target="_blank"
        aria-label="Bezoek Faktor 22 website">© Faktor 22</a
      > | v.1.0
    </span>
  </div>
</footer>

<style>
  footer {
    margin: 0 auto; /* Centreert de footer */
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    color: #fff;
    font-size: 14px;
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .logo {
    margin-right: 15px;
  }
  .version {
    margin-left: auto; /* Duwt de versie naar de rechterkant */
    background-color: #dde4ec;
    padding: 5px 10px;
    border-radius: 5px;
    color: #1d1f26;
  }

  .version a {
    color: #1d1f26;
  }
  @media (max-width: 768px) {
    footer {
      width: calc(100% - 40px);
      padding: 20px;
    }
  }
</style>
