<script>
  import Header from "../components/Header.svelte";
  import { push } from "svelte-spa-router";
  import Footer from "../components/Footer.svelte";

  function navigateToLent() {
    push("/lent");
  }

  function navigateToElst() {
    push("/elst");
  }
</script>

<Header />

<main>
  <div class="grid-container">
    <div class="block left">
      <div class="content">
        <h1>Kapper in Lent</h1>
        <div class="info">
          <p class="subtitle">Theo Dobbestraat 189<br /> 6663 RJ Lent</p>
          <!-- svelte-ignore a11y-interactive-supports-focus -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="icon"
            on:click={navigateToLent}
            role="button"
            aria-label="Ga naar Lent"
          >
            <img src="/img/icon-next.svg" alt="Navigeer naar Lent" />
          </div>
        </div>
      </div>
    </div>
    <div class="block right">
      <div class="content">
        <h1>Kapper in Elst</h1>
        <div class="info">
          <p class="subtitle">Dorpsstraat 97<br />6661 EJ Elst</p>
          <!-- svelte-ignore a11y-interactive-supports-focus -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="icon"
            on:click={navigateToElst}
            role="button"
            aria-label="Ga naar Elst"
          >
            <img src="/img/icon-next.svg" alt="Navigeer naar Elst" />
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<Footer />

<style>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 110px;
    align-items: start;
    justify-items: center;
    padding: 10px 20px 0 20px;
  }

  .content {
    z-index: 2;
    align-self: flex-start;
  }
  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 20px #0c0f13;
    height: calc(100vh - 220px);
    background-color: #fff;
    width: 100%;
    border-radius: 25px;
    padding: 20px;
    position: relative;
    text-align: left;
    margin: 10px 0 20px 0;
  }
  .block h1 {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
    color: white;
    background-color: transparent !important;
    z-index: 2;
    margin-top: auto;
    font-size: 5.5rem;
    text-shadow: 2px 5px 15px rgba(0, 0, 0, 0.6);
  }
  .block::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 25px;
  }
  .left {
    background-image: url("/img/noahkappers-locatie-lent.jpg");
    background-size: cover;
    background-position: center;
  }
  .right {
    background-image: url("/img/noahkappers-locatie-elst.jpg");
    background-size: cover;
    background-position: center;
  }
  .info {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px;
    border-radius: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 80px);
  }
  .icon img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .subtitle {
    font-size: 22px;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      margin: 0 20px;
    }
    .block {
      height: calc(75vh - 170px);
    }
    .block h1 {
      font-size: 4rem;
    }
    .subtitle {
      font-size: 18px;
    }
    .icon img {
      width: 40px;
      height: 40px;
    }
  }
</style>
