<script>
  import Footer from "../components/Footer.svelte";
  import { push } from "svelte-spa-router";

  function navigateToHome() {
    push("/");
  }
</script>

<header>
  <!-- svelte-ignore a11y-no-noninteractive-element-to-interactive-role -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <img
    src="../img/icon-noahkappers.svg"
    alt="Icon Noah Kappers"
    class="icon"
    on:click={navigateToHome}
    role="button"
    aria-label="Terug naar homepage"
  />
  <nav>
    <a
      href="tel:0031684812004"
      target="_blank"
      class="nav-link"
      aria-label="Bel Noah Kappers op +31 6 84 81 20 04"
    >
      <img src="../img/icon-phone.svg" alt="Telefoon icoon" />
    </a>
    <a
      href="https://salonkee.nl/salon/noah-kappers"
      target="_blank"
      class="nav-afspraak"
      aria-label="Maak een afspraak bij Noah Kappers">Afspraak maken</a
    >
  </nav>
</header>

<main>
  <div class="hero">
    <p class="hero-sub">Noah Kappers</p>
    <h1>Locatie Lent</h1>
    <p class="hero-adres">Theo Dobbestraat 189 <br />6663 RJ Lent</p>
    <p class="hero-tel">
      <a
        href="tel:0031684812004"
        target="_blank"
        aria-label="Bel Noah Kappers Lent">+31 6 84 81 20 04</a
      >
    </p>
  </div>
  <section class="content">
    <p>
      Welkom bij Noah Kappers in Lent, waar professionele knip- en
      stylingdiensten centraal staan. Gelegen in het hart van Lent, bieden wij
      een breed assortiment aan haarverzorgingsdiensten. Ons team van ervaren
      stylisten zet zich in om elke klant een kwaliteitsvolle en
      tevredenstellende ervaring te bieden.
    </p>
  </section>
  <div class="openingstijden">
    <p>Openingstijden: dinsdag t/m zaterdag: 09:30 - 18:00 uur</p>
  </div>
  <div class="images">
    <img
      src="/img/noah-kappers-foto-1.jpg"
      alt="haarstijl-1-locatie-lent"
      aria-label="Haarstijl 1 van Noah Kappers Lent"
    />
    <img
      src="/img/noah-kappers-foto-2.jpg"
      alt="haarstijl-2-locatie-lent"
      aria-label="Haarstijl 2 van Noah Kappers Lent"
    />
    <img
      src="/img/noah-kappers-foto-4.jpg"
      alt="haarstijl-4-locatie-lent"
      aria-label="Haarstijl 4 van Noah Kappers Lent"
    />
  </div>
  <div class="images">
    <img
      src="/img/noah-kappers-foto-5.jpg"
      alt="haarstijl-5-locatie-lent"
      aria-label="Haarstijl 5 van Noah Kappers Lent"
    />
    <img
      src="/img/noah-kappers-foto-3.jpg"
      alt="haarstijl-3-locatie-lent"
      aria-label="Haarstijl 3 van Noah Kappers Lent"
    />
    <img
      src="/img/noah-kappers-foto-6.jpg"
      alt="haarstijl-6-locatie-lent"
      aria-label="Haarstijl 6 van Noah Kappers Lent"
    />
  </div>
  <section class="prijzen">
    <h2>Prijzen</h2>
    <p>
      Hieronder vindt u de prijzen van ons uitgebreide aanbod. Wij zorgen voor
      topkwaliteit in elke behandeling.
    </p>
    <h3 class="prijzen-cat" aria-label="Prijzen voor mannen bij Noah Kappers">
      Heren
    </h3>
    <div class="prijzen-tab">
      <p>Knippen</p>
      <p>25,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Knippen + baard scheren</p>
      <p>35,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Knippen + wassen</p>
      <p>27,50</p>
    </div>
    <div class="prijzen-tab">
      <p>Tondeuse</p>
      <p>18,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Epileren (wenkbrauw)</p>
      <p>10,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Epileren (wang + wenkbrauw)</p>
      <p>12,50</p>
    </div>
    <div class="prijzen-tab">
      <p>Baard scheren</p>
      <p>10,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Baard modelleren</p>
      <p>15,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Baard modelleren + verven</p>
      <p>20,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Gezichtsbehandeling</p>
      <p>20,-</p>
    </div>
    <div class="prijzen-tab">
      <p>VIP arrangement (knippen, wassen, baard en gezichtsbehandeling)</p>
      <p>50,-</p>
    </div>

    <h3 class="prijzen-cat" aria-label="Prijzen voor vrouwen bij Noah Kappers">
      Dames
    </h3>
    <div class="prijzen-tab">
      <p>Knippen</p>
      <p>30,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Knippen + Wassen</p>
      <p>32,50</p>
    </div>

    <div class="prijzen-tab">
      <p>Föhnen + Wassen (korthaar)</p>
      <p>25,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Föhnen + Wassen (langhaar)</p>
      <p>30,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Opsteken</p>
      <p>40,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Epileren (wenkbrauw)</p>
      <p>12,50</p>
    </div>
    <div class="prijzen-tab">
      <p>Epileren wenkbrauwen + Verven</p>
      <p>20,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Heel gezicht Epileren</p>
      <p>20,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Bovenlip Epileren</p>
      <p>5,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Verven (uitgroei)</p>
      <p>35,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Verven (korthaar)</p>
      <p>45,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Verven (langhaar)</p>
      <p>60,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Highlights 12 stuks</p>
      <p>54,50</p>
    </div>
    <div class="prijzen-tab">
      <p>Highlights 25 stuks</p>
      <p>109,-</p>
    </div>

    <div class="prijzen-tab">
      <p>Balyage</p>
      <p>v.a. 150,-</p>
    </div>

    <h3 class="prijzen-cat" aria-label="Prijzen voor kinderen bij Noah Kappers">
      Kinderen
    </h3>
    <div class="prijzen-tab">
      <p>Knippen (kinderen t/m 12 jaar)</p>
      <p>17,50</p>
    </div>
  </section>
</main>

<Footer />

<style>
  main {
    margin-top: 20px;
    margin-bottom: 80px;
    color: #fff;
  }
  p {
    line-height: 28px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
  }
  nav {
    display: flex;
    align-items: center;
  }
  .nav-afspraak {
    margin-left: 20px;
    border-radius: 5px;
    padding: 5px 7px;
    font-weight: 600;
    background-color: #a4fff6;
    color: #1d1f26;
  }
  .icon {
    width: 42px;
  }

  section.content {
    padding: 20px 0 5px 0;
    width: 600px;
    margin: 0 auto;
    font-size: 14px;
  }
  section.prijzen {
    padding: 10px 0;
    width: 600px;
    margin: 0 auto;
  }
  p {
    font-size: 18px;
    font-weight: 200;
  }
  h2 {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .prijzen-cat {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1d1f26;
    background-color: #cfb29f;
    padding: 5px 10px;
    border-radius: 10px;
    display: inline-block;
  }
  .prijzen-tab {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #303443;
    padding: 15px 20px;
    border-radius: 10px;
  }
  .prijzen-tab p {
    margin: 0;
  }

  .openingstijden {
    border: 1px solid #fff;
    border-radius: 15px;
    width: 555px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 45px !important;
  }
  .images {
    display: flex;
    margin: 30px auto;
    width: 800px;
    gap: 30px;
  }
  .images img {
    justify-content: space-between;
    background-color: #c3ab84;
    border-radius: 25px;
    width: 31%;
    height: auto;
  }
  .hero {
    width: 800px;
    height: 333px;
    margin: 0 auto; /* Centreert de hero section */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("/img/noahkappers-locatie-lent.jpg");
    background-color: rgba(0, 0, 0, 0.4);
    background-size: cover;
    background-position: center;
    border-radius: 25px;
    position: relative;
  }
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    border-radius: 25px;
  }

  .hero p,
  .hero h1 {
    margin: 0 auto;
    width: 600px;
    text-align: left;
    z-index: 2;
    color: #fff;
  }

  .hero-sub {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }

  .hero-adres {
    font-size: 1.2rem;
    margin-top: 30px !important;
    text-shadow: 2px 5px 5px rgba(0, 0, 0, 0.6);
  }

  .hero-tel {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 10px !important;
    text-shadow: 2px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .hero-tel a {
    color: #fff;
  }

  .hero h1 {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
    font-size: 4rem;
    text-shadow: 2px 5px 15px rgba(0, 0, 0, 0.6);
  }

  @media (max-width: 768px) {
    header {
      padding: 20px;
    }
    p {
      margin: 0;
    }
    h2 {
      margin: 10px 0 20px 0;
    }
    .icon {
      width: 35px;
    }
    .nav-afspraak {
      margin-left: 0;
      padding: 5px;
    }
    .nav-link {
      display: none;
    }
    .hero {
      width: calc(100% - 40px);
      height: 400px;
      margin: 20px;
    }
    .hero p,
    .hero h1 {
      width: auto;
      padding: 0 20px;
      margin: 0;
    }
    section.content,
    section.prijzen {
      width: calc(100% - 40px);
      padding: 20px;
    }
    .images {
      display: none;
    }
  }
</style>
