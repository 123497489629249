<script>
  import Router from "svelte-spa-router";
  import Home from "./routes/Home.svelte";
  import Lent from "./routes/Lent.svelte";
  import Elst from "./routes/Elst.svelte";

  // Definieer routes
  const routes = {
    "/": Home,
    "/lent": Lent,
    "/elst": Elst
  };
</script>

<Router {routes} />

<style>
</style>
