<script>
  import { push } from "svelte-spa-router";

  function navigateToHome() {
    push("/");
  }

  function navigateToLent() {
    push("/lent");
  }

  function navigateToElst() {
    push("/elst");
  }
</script>

<header>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <img
    src="../img/logo-noahkappers.svg"
    alt="Logo"
    class="logo"
    on:click={navigateToHome}
  />
  <nav>
    <span class="locatie">Locatie</span>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="nav-link" on:click={navigateToLent}>Lent</div>

    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="nav-link" on:click={navigateToElst}>Elst</div>
  </nav>
</header>

<style>
  header {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
  }
  nav {
    display: flex;
    align-items: center;
  }
  .nav-link {
    margin-left: 10px;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    background-color: #cfb29f;
    color: #1d1f26;
    cursor: pointer;
  }

  .locatie {
    color: #fff;
    font-size: 17px;
  }

  @media (max-width: 768px) {
    header {
      padding: 20px;
    }
    .logo {
      width: 170px;
    }
    .nav-link {
      margin-left: 10px;
    }
    .locatie {
      display: none;
    }
  }
</style>
